body {
  background: url(./assets/background.png);
  background-position: 50%;
}
.header {
  width: 100%;
  display: flex;
  position: sticky;
  background: rgba(249, 171, 3, 0.7);
}
.logoIMG {
  padding: 1%;
  width: 15%;
}
.logoIMG >img {
  width:35%;
  height:60px;
}
.rightActive {
  width: 100%;
  display: flex;
  justify-content: right;
}
.fire {
  background-color: white;
  box-shadow: rgb(25 19 38 / 10%) 0px 2px 12px -8px, rgb(25 19 38 / 10%) 0px 1px 1px;
  border-radius: 10px;
  font-size: 40px;
  padding-left: 50px;
  padding-right: 50px;
  color: rgb(98, 66, 163);
}
.backSiteBtn {
  margin-top: 2%;
  margin-right: 2%;
  height: 50px;
  background-color: rgb(98, 66, 163);
  color:white;
  outline: none;
  border:none;
  border-radius: 10px;
}
.backSiteBtn >h4 {
  font-weight: 600 !important;
  font-size: 13px;
}
.backSiteBtn >a {
  color:white;
  font-size: 13px;
  font-weight: 600;
  padding: 20px;
}
.swap > button {
  font-size: 20px;
  font-weight: 700;
  color: rgb(69, 42, 122) !important;
}
.backBtnDiv2 >button > a {
  font-size: 20px;
  font-weight: 700;
  color: rgb(69, 42, 122);
}
.App {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  flex: 1 1 0%;
  overflow: hidden auto;
}
::-webkit-scrollbar-thumb {
  border-radius: 7px;
  background: #2B2D42;
  border-left: 3px solid #EDF2F4;
  border-right: 3px solid #EDF2F4;
}
.backBtnDiv1 {
  width: 80%;
}
.backBtnDiv2 {
  width: 10%;
}
.backBtnDiv3 {
  width: 10%;
}
@media (max-width:500px) {
  .logoIMG {
    width: 30%;
  } 
  .logoIMG >img {
    height: 40px;
  }
  .rightActive{
    width: 50%;
  }
}
@media (max-width:800px) {
  .header {
    padding-top: 3%;
  }
  .rightActive, .logoIMG {
    width: 50%;
  }
  .backBtnDiv1 {
    width: 60%;
  }
  .backBtnDiv2 {
    width: 30%;
  }
  .backBtnDiv3 {
    width: 10%;
  }
}
